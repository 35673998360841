import PropTypes from 'prop-types';
import { Log } from '@brandedholdings/react-components/build/utils';
import React from 'react';
import {
    Hero,
    SEO,
    TrustIcons,
} from '@brandedholdings/react-components';
import PagePost from '../../models/PagePost';
import { home as pageMeta } from '../../data/page_meta.yml';
import { siteMeta } from '../../data/site_meta.yml';
import PageWrapper from '../../components/DefaultPageWrapper';
import OfferForm from '../../components/OfferForm';


class GetStarted extends React.Component {
    constructor(props) {
        super(props);
        this.state = { variant: 'default' };
    }

    getChildContext() {
        return { siteMeta, pageMeta };
    }

    onFormSubmit = (e, fields) => {
        e.preventDefault();
        console.warn('FORM SUBMIT', fields);
        window.location = '/results/';
    };

    render() {
        const page = this.props;

        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);

        Log.toDataLayer({
            event: 'get_started',
        });

        return (
            <PageWrapper>
                <SEO post={post} />
                <Hero
                    heading="Answer a few simple questions"
                    subheading="and we'll help you find great&nbsp;offers"
                    actionText={null}
                    actionLink="#"
                    heroModifiers="hero--arrow"
                    actionModifiers={null}
                />
                <div className="layout">
                    <div className="layout-content text-align--center">
                        <OfferForm interlincx />
                        <br />
                        <TrustIcons wrapper={({ children }) => <div>{children}</div>} icons={['secured', 'mcafee-secure']} cssModifiers={['alt']} siteMeta={siteMeta} height={48} />
                    </div>
                </div>
            </PageWrapper>
        );
    }
}

GetStarted.childContextTypes = {
    siteMeta: PropTypes.object,
    pageMeta: PropTypes.object,
};

export default GetStarted;
